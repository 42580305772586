
































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import CardItem from "@/components/CardItem.vue";
import { throttle } from "lodash";
import { Toast,Popup } from "vant";
import SyntheticDialog from "@/components/SyntheticDialog.vue";
import { IEntitySupply } from "@/api/supply-package.api";
import { IVoucherDetailV2, VoucherApi } from "@/api/voucher.api";
import AddressForm from "@/components/AddressForm.vue";
import { copy, getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {
    SyntheticDialog,
    CardItem,
    AddressForm,
    Popup
  },
})
export default class CamiloCode extends Vue {
  @Prop() readonly voucher!: IVoucherDetailV2;
  @Prop() readonly entity!: IEntitySupply;
  @Prop() readonly id!: number;
  @Prop() readonly isEntity!: boolean;
  @Prop() readonly support_recycle!: boolean;
  @Prop({ default: 0 }) readonly type!: number;
  Tips = true
  is_upgrade = true;
  async mounted() {
    if (this.type) return;
    const result = await VoucherApi.getLoopInfo(this.voucher.group_character)
      .catch(() => undefined);
    this.is_upgrade = Boolean(result);
  }
  @Emit()
  againHandler(){
  }
  openTips(){
      this.againHandler()
  }
  throttleHandleOpenedEntity = throttle(
    this.handleOpenedEntity.bind(this),
    1500
  );
  confirmHandler(){
    this.throttleHandleOpened()
  }

  throttleHandleOpened = throttle(this.handleOpened.bind(this), 1500);
  throttleHandleSynthetic(){
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/synthetic`);
    }
    this.$router.push({name:"Synthetic"})
  }
  throttleHandlesell() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/hangsell?detail_id=${this.voucher.detail_id}`);
    }
    this.$router.push({
      name: "hangsell",
      query: {
        detail_id: this.voucher.detail_id,
      },
    });
  }
  get status() {
    return this.entity.status;
  }

  get code_code_value() {
    if (this.type) return false;
    return this.voucher.code_code_value;
  }

  get code_is_seal() {
    if (this.type) return false;
    return this.voucher.code_is_seal;
  }

  get cover() {
    if (this.type) return this.entity.cover;
    return this.voucher.group_cover;
  }

  get character() {
    if (this.type) return this.entity.character;
    return this.voucher.group_character;
  }

  get code_update_time() {
    if (this.type) return this.entity.create_time;
    return this.voucher.code_update_time;
  }

  get code_record() {
    if (this.type) return this.entity.record;
    return this.voucher.code_record;
  }

  get title() {
    if (this.type) return this.entity.title;
    return this.voucher.group_title;
  }

  handleCopy() {
    if (this.voucher.code_is_seal) return;
    copy(this.voucher.code_code_value);
    Toast.success("已复制");
    InteractionLib.copyListen();
  }

  @Emit()
  handleSynthetic() {}

  @Emit()
  refreshData() {
    return;
  }

  handleOpenedEntity() {
    this.$router.replace({
      name: "ApplyDelivery",
      params: { id: String(this.id) },
    });
  }

  handleOpenedSupplyDetail() {
    this.$router.push({
      name: "MyDeliveryRecord",
      query: { time: String(getNow()) },
    });
  }

  async handleOpened() {
    const toast = Toast.loading({
      mask: true,
      message: "拆封中...",
    });
    await VoucherApi.openedMyVoucher(this.id)
      .then(() => {
        toast.message = "拆封完毕";
        return this.refreshData();
      })
      .catch((err) => Toast.fail(err.message));
    toast.close();
  }

  openNewURI(url: string) {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(url);
    }
    window.open(url);
  }
}
